class Log {
  generateMessage(level, message, data) {
    // Set the prefix which will cause debug to enable the message
    try {
      console.log(`[${level}] ${message}`, data || "");
    } catch (exp) {}
  }

  log(message, data) {
    //map Log.log to Log.info
    return this.generateMessage("info", message, data);
  }

  trace(message, data) {
    return this.generateMessage("trace", message, data);
  }

  info(message, data) {
    return this.generateMessage("info", message, data);
  }

  warn(message, data) {
    return this.generateMessage("warn", message, data);
  }

  error(message, source) {
    return this.generateMessage("error", message, source);
  }

  rest(message, source) {
    return this.generateMessage("rest", message, source);
  }
}

export default new Log();
