import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
//import "url-search-params-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "production") {
   localStorage.setItem("debug", "playcz-player:*");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
