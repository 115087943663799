import React, { Fragment, Component } from "react";
import { Helmet } from "react-helmet";
import ReactAudioPlayer from "react-audio-player";
import HTTP from "../HTTP.js";
import Log from "../Log.js";

import IsNull from "lodash/isNull";
import IsObject from "lodash/isObject";
import IsArray from "lodash/isArray";
import IsEmpty from "lodash/isEmpty";
import { isMobile } from "react-device-detect";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/lab/Slider";

import IconPlay from "@material-ui/icons/PlayArrowTwoTone";
import IconStop from "@material-ui/icons/StopTwoTone";
import IconVolume from "@material-ui/icons/VolumeUpTwoTone";
import IconVolumeOff from "@material-ui/icons/VolumeOffTwoTone";
import IconHD from "@material-ui/icons/HdTwoTone";
//import IconWait from "@material-ui/icons/LoopTwoTone";
import IconWait from "@material-ui/core/CircularProgress";
import IconSettings from "@material-ui/icons/SettingsTwoTone";
import IconFavorite from "@material-ui/icons/FavoriteTwoTone";

import IconError from "@material-ui/icons/ErrorTwoTone";

import ImgLogo from "../assets/logo_white_300.png";
import ImgPlay from "../assets/playcz_small.png";
import AudioSilence from "../assets/silence.mp3";

/* https://github.com/justinmc/react-audio-player#readme */

class Player extends Component {
   constructor(props) {
      Log.info("Player constructor", props);
      super(props);

      this.state = {
         formatCurrent: "",
         bitrateCurrent: 0,

         formatPrefered: !IsEmpty(props.format) ? props.format : "mp3",
         bitratePrefered: !IsEmpty(props.bitrate) ? props.bitrate : 128,

         streamsSupported: {},
         streamUrl: AudioSilence,
         onairSong: "---",
         onairArtist: "---",
         onairImage: ImgPlay,

         radioTitle: "-",
         radioDescription: "",
         radioLogo: ImgPlay,

         playerVolume: 1,
         playerVolumeMuted: false,
         playerShouldPlay: false,
         playerState: "stopped",
         playerStateLastPosition: -1,
         hasPlayError: false,

         menuToolbarConfig: null
      };

      this.audioPlayer = null;
      this.onairRefreshTimer = null;
      this.watchdogTimer = null;

      this.getOnairData = this.getOnairData.bind(this);
      this.startStream = this.startStream.bind(this);
      this.stopStream = this.stopStream.bind(this);
      this.setPlayerVolume = this.setPlayerVolume.bind(this);
      this.setPlayerVolumeMute = this.setPlayerVolumeMute.bind(this);
      this.watchdogDo = this.watchdogDo.bind(this);

      this.playerOnListen = this.playerOnListen.bind(this);
      this.playerOnAbort = this.playerOnAbort.bind(this);
      this.playerOnEnded = this.playerOnEnded.bind(this);
      this.playerOnError = this.playerOnError.bind(this);
      this.playerOnPause = this.playerOnPause.bind(this);
      this.playerOnPlay = this.playerOnPlay.bind(this);
      this.playerOnVolumeChanged = this.playerOnVolumeChanged.bind(this);
   }

   componentDidMount() {
      this.getRadioStream();
      this.getRadioStreams();

      // restore volume from localStorage
      var playerVolume = localStorage.getItem("playerVolume");
      Log.info("stored playerVolume", playerVolume);
      if (!IsEmpty(playerVolume)) {
         playerVolume = parseFloat(playerVolume);
         this.setState({ playerVolume });
      }

      this.startWatchdog();
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (prevProps.shortcut !== this.props.shortcut) {
         this.stopStream();
         this.getRadioStream();
         this.getRadioStreams();
      }
   };

   componentWillUnmount() {
      this.stopOnairRefreshTimer(false);
      this.stopWatchdog();
   }

   getRadioStream() {
      // getStreamMobile | getStream
      let apiFunction = isMobile ? "getStreamMobile" : "getStream";
      let apiEndPoint =
         this.props.cfg.apiUrl +
         apiFunction +
         "/" +
         this.props.shortcut +
         "/" +
         this.state.formatPrefered +
         "/" +
         this.state.bitratePrefered;
      let quiteRequest = false; // optional

      HTTP.get(apiEndPoint, quiteRequest).then(
         function (json) {
            Log.info(" === RESPONSE for: " + apiEndPoint, json);
            // json.response

            if (!IsObject(json.data.stream)) {
               // handle error
               return false;
            }

            localStorage.setItem("playerLastShortcut", this.props.shortcut);

            this.setState(
               {
                  streamUrl: json.data.stream.pubpoint,
                  formatCurrent: json.data.stream.stream_type,
                  bitrateCurrent: json.data.stream.stream_bitrate,

                  radioTitle: json.data.radio.title || '---',
                  radioDescription: json.data.radio.description || '---',
                  radioLogo: json.data.radio.logoimg_m
               },
               () => {
                  setTimeout(() => {
                     this.startStream();
                  }, 500);
               }
            );
         }.bind(this)
      );
   }

   getRadioStreams() {
      // getAllStreamsMobile | getAllStreams
      let apiFunction = isMobile ? "getAllStreamsMobile" : "getAllStreams";
      let apiEndPoint = this.props.cfg.apiUrl + apiFunction + "/" + this.props.shortcut;
      let quiteRequest = false; // optional

      HTTP.get(apiEndPoint, quiteRequest).then(
         function (json) {
            Log.info(" === RESPONSE for: " + apiEndPoint, json);
            // json.response

            if (!IsObject(json.data.streams)) {
               // handle error
               return false;
            }

            this.setState({ streamsSupported: json.data.streams });
         }.bind(this)
      );
   }

   getOnairData() {
      let apiEndPoint = this.props.cfg.onairUrl + this.props.shortcut + ".json";
      let quiteRequest = false; // optional

      HTTP.get(apiEndPoint, quiteRequest).then(
         function (json) {
            Log.info(" === RESPONSE for: " + apiEndPoint, json);
            // json.response
            if (!IsObject(json)) {
               // handle error
               return false;
            }

            var onairArtist = !IsEmpty(json.artist) ? json.artist : this.state.radioDescription;
            var onairSong = !IsEmpty(json.song) ? json.song : this.state.radioTitle;
            var onairImage = !IsEmpty(json.img) ? json.img : ImgPlay;

            if (onairSong !== this.state.onairSong && onairArtist !== this.state.onairArtist) {
               Log.info("Calling onSongChange callback...");
               if (!IsNull(this.props.onSongChange)) {
                  this.props.onSongChange(onairSong, onairArtist);
               }
            }

            this.setState({ onairArtist, onairSong, onairImage });
         }.bind(this)
      );
   }

   setFormatBitrate(format, bitrate) {
      if (!IsEmpty(format)) localStorage.setItem("playerPreferedFormat", format);
      if (bitrate > 0) localStorage.setItem("playerPreferedBitrate", bitrate);

      this.setState({ formatPrefered: format, bitratePrefered: bitrate }, () => {
         this.getRadioStream();
      });
   }

   startStream() {
      Log.info("startStream()", this.audioPlayer);
      if (!IsNull(this.audioPlayer)) {
         Log.info("startStream() - setting up stream url...", this.state.streamUrl);

         this.getOnairData();
         this.startOnairRefreshTimer();

         this.setState({ playerState: "loading", playerShouldPlay: true }, () => {
            this.audioPlayer.src = this.state.streamUrl;
            setImmediate(() => {
               this.audioPlayer.play();
            });
         });
      }
   }

   stopStream() {
      this.audioPlayer.src = AudioSilence;
      this.stopOnairRefreshTimer(true);
      this.setState({ playerStateLastPosition: -1, playerState: "stopped", playerShouldPlay: false });
   }

   setPlayerVolume(vol, isCallback) {
      let volume = parseFloat(vol);

      Log.info("volume change", volume);
      this.setState(state => {
         var isMuted = state.playerVolumeMuted;
         var shouldUnMute = false;

         if (isMuted && !isCallback) {
            shouldUnMute = true;
            isMuted = false;
         }

         if (!IsNull(this.audioPlayer)) {
            this.audioPlayer.volume = volume.toString();
            if (shouldUnMute) this.audioPlayer.muted = false;
         }

         localStorage.setItem("playerVolume", volume);

         return { playerVolume: volume, playerVolumeMuted: isMuted };
      });
   }

   setPlayerVolumeMute() {
      this.setState(state => {
         let isMuted = !state.playerVolumeMuted;

         if (!IsNull(this.audioPlayer)) {
            this.audioPlayer.muted = isMuted;
         }

         return {
            playerVolumeMuted: isMuted
         };
      });
   }

   startOnairRefreshTimer() {
      this.onairRefreshTimer = setInterval(this.getOnairData, 15000);
   }

   stopOnairRefreshTimer(resetOniarData) {
      if (!IsNull(this.onairRefreshTimer)) {
         clearInterval(this.onairRefreshTimer);
      }

      if (resetOniarData) {
         var onairArtist = this.state.radioTitle;
         var onairSong = this.state.radioDescription;
         var onairImage = this.state.radioLogo;

         this.setState({ onairArtist, onairSong, onairImage });
      }
   }

   watchdogDo() {
      if (this.state.hasPlayError && this.state.playerState !== "loading") {
         Log.error("Player has error, try to recover...");
         this.startStream();
      }

      if (this.state.playerShouldPlay) {
         if (this.state.playerState === "stalled") {
            Log.error("Player is stalled, try to recover...");
            this.startStream();
         }
      }
   }

   startWatchdog() {
      this.watchdogTimer = setInterval(this.watchdogDo, 20000);
   }

   stopWatchdog() {
      if (!IsNull(this.watchdogTimer)) {
         clearInterval(this.watchdogTimer);
      }
   }

   menuToolbarConfigHandleClick = event => {
      this.setState({ menuToolbarConfig: event.currentTarget });
   };

   menuToolbarConfigHandleClose = () => {
      this.setState({ menuToolbarConfig: null });
   };

   playerOnListen(e) {
      Log.info("player callback", "playerOnListen", e);
      this.setState(state => {
         if (state.playerStateLastPosition === e && e !== -1) {
            return {
               playerState: "stalled",
               playerStateLastPosition: e,
               hasPlayError: true
            };
         }

         return {
            playerState: "playing",
            playerStateLastPosition: e,
            hasPlayError: false
         };
      });
   }

   playerOnAbort(e) {
      Log.warn("player callback", "playerOnAbort", e);
      this.setState({ playerState: "stopped" });
      //this.stopStream();
   }
   playerOnEnded(e) {
      Log.info("player callback", "playerOnEnded", e);
      this.setState({ playerState: "stopped" });
      this.stopStream();
   }
   playerOnError(e) {
      Log.warn("player callback", "playerOnError", e);
      this.setState({ playerState: "stalled", hasPlayError: true });
      //this.stopStream();
   }
   playerOnPause(e) {
      Log.info("player callback", "playerOnPause", e);
      this.setState({ playerState: "paused" });
      this.stopStream();
   }
   playerOnPlay(e) {
      Log.info("player callback", "playerOnPlay", e);
      this.setState({ playerState: "loading" });
   }
   playerOnVolumeChanged(e) {
      Log.info("player callback", "playerOnVolumeChanged", e);
      let volume = e.target.volume;
      this.setPlayerVolume(volume, true);
   }

   render() {
      return (
         <Fragment>
            <Helmet>
               <title>{this.state.radioTitle} :: PLAY.CZ</title>
            </Helmet>

            <div className="playerObject" style={{ margin: "6px 0" }}>
               <AppBar position="static">
                  <Toolbar variant="dense" style={styles.radioTitle}>
                     <Avatar src={this.state.radioLogo} style={styles.radioLogo} />
                     <Typography variant="subtitle1" color="inherit" style={{ flexGrow: 1 }}>
                        {this.state.radioTitle}
                     </Typography>

                     <a href="https://www.play.cz" target="_blank"><img src={ImgLogo} alt="PLAY.cz" style={{ height: '25px' }} /></a>
                  </Toolbar>
               </AppBar>

               <Card style={styles.card}>
                  <div style={styles.details}>
                     <CardContent style={styles.onairBlock}>
                        <div style={styles.onairSong}>{this.state.onairSong}</div>
                        <div style={styles.onairArtist}>{this.state.onairArtist}</div>
                     </CardContent>

                     <div style={styles.controls}>
                        {this.renderPlayButton()}
                        {this.renderVolumeControl()}
                        {this.renderVolumeMuteControl()}
                        {this.renderHdIcon()}

                        {this.props.enableFavorites && this.renderFavButton()}
                     </div>
                  </div>
                  <CardMedia
                     style={styles.onairImage}
                     image={this.state.onairImage}
                     title={this.state.onairArtist + " - " + this.state.onairSong}
                  />
                  {this.renderQualityMenu()}
               </Card>

               {this.renderDebug()}

               <ReactAudioPlayer
                  src={AudioSilence}
                  ref={e => {
                     if (IsNull(this.audioPlayer)) {
                        this.audioPlayer = e.audioEl;
                     }
                  }}
                  volume={this.state.playerVolume}
                  autoPlay={false}
                  controls={false}
                  style={{ width: "100%" }}
                  listenInterval={5000}
                  onListen={this.playerOnListen}
                  onAbort={this.playerOnAbort}
                  onEnded={this.playerOnEnded}
                  onError={this.playerOnError}
                  onPause={this.playerOnPause}
                  onPlay={this.playerOnPlay}
                  onVolumeChanged={this.playerOnVolumeChanged}
               />
            </div>
         </Fragment>
      );
   }

   renderToolbarConfigStreamItem(format, item) {
      //Log.info("renderToolbarConfigStreamItem", { format, item });
      return (
         <MenuItem
            key={format + item}
            onClick={() => {
               this.setFormatBitrate(format, item);
               this.menuToolbarConfigHandleClose();
            }}
            selected={
               format === this.state.formatCurrent && item === this.state.bitrateCurrent ? true : false
            }
         >
            {format} - {item} kbps
         </MenuItem>
      );
   }
   renderToolbarConfig() {
      Log.info("renderToolbarConfig", this.state.streamsSupported);

      if (!IsObject(this.state.streamsSupported)) return null;

      return (
         <Fragment>
            <IconButton style={styles.toolbarIconButton} onClick={this.menuToolbarConfigHandleClick}>
               <IconSettings style={styles.toolbarIcon} />
            </IconButton>
         </Fragment>
      );
   }

   renderPlayButton() {
      let iconColor = "primary";

      switch (this.state.playerState) {
         case "loading":
            return (
               <IconButton
                  aria-label="Rádio se načítá..."
                  onClick={() => {
                     this.stopStream();
                  }}
               >
                  <IconWait color={iconColor} style={styles.controlIcon} />
               </IconButton>
            );

         default:
         case "stopped":
         case "paused":
            if (this.state.hasPlayError) {
               return (
                  <IconButton
                     aria-label="Spustit rádio"
                     onClick={() => {
                        this.startStream();
                     }}
                  >
                     <IconError color={iconColor} style={styles.controlIcon} />
                  </IconButton>
               );
            }

            return (
               <IconButton
                  aria-label="Spustit rádio"
                  onClick={() => {
                     this.startStream();
                  }}
               >
                  <IconPlay color={iconColor} style={styles.controlIcon} />
               </IconButton>
            );

         case "stalled":
            return (
               <IconButton
                  aria-label="Spustit rádio"
                  onClick={() => {
                     this.startStream();
                  }}
               >
                  <IconError color={iconColor} style={styles.controlIcon} />
               </IconButton>
            );

         case "playing":
            return (
               <IconButton
                  aria-label="Zastavit rádio"
                  onClick={() => {
                     this.stopStream();
                  }}
               >
                  <IconStop color={iconColor} style={styles.controlIcon} />
               </IconButton>
            );
      }
   }

   renderVolumeControl() {
      return (
         <Slider
            value={this.state.playerVolume * 100}
            aria-labelledby="Hlasitost"
            min={0}
            max={100}
            step={10}
            onChange={(event, value) => {
               this.setPlayerVolume(value / 100);
            }}
         />
      );
   }

   renderVolumeMuteControl() {
      return (
         <IconButton aria-label="Ztlumit rádio" onClick={this.setPlayerVolumeMute}>
            {this.state.playerVolumeMuted ? (
               <IconVolumeOff color="secondary" style={styles.controlIcon} />
            ) : (
               <IconVolume color="primary" style={styles.controlIcon} />
            )}
         </IconButton>
      );
   }

   renderQualityMenu() {
      return (
         <Menu
            id="simple-menu"
            anchorEl={this.state.menuToolbarConfig}
            open={Boolean(this.state.menuToolbarConfig)}
            onClose={this.menuToolbarConfigHandleClose}
         >
            {IsArray(this.state.streamsSupported.aac) &&
               this.state.streamsSupported.aac.map((item, index) => {
                  return this.renderToolbarConfigStreamItem("aac", item);
               })}

            {IsArray(this.state.streamsSupported.mp3) &&
               this.state.streamsSupported.mp3.map((item, index) => {
                  return this.renderToolbarConfigStreamItem("mp3", item);
               })}
         </Menu>
      );
   }

   renderFavButton() {
      var isFav = false;

      return (
         <Fragment>
            <Fragment>
               <IconButton onClick={() => { }}>
                  <IconFavorite color={isFav ? "primary" : "secondary"} style={styles.controlIcon} />
               </IconButton>
            </Fragment>
         </Fragment>
      );
   }

   renderHdIcon() {
      var isHD = false;

      if (this.state.formatCurrent === "mp3" && this.state.bitrateCurrent > 96) {
         isHD = true;
      }
      if (this.state.formatCurrent === "aac" && this.state.bitrateCurrent > 48) {
         isHD = true;
      }

      return (
         <Fragment>
            <IconButton onClick={this.menuToolbarConfigHandleClick}>
               <IconHD color={isHD ? "primary" : "secondary"} style={styles.controlIcon} />
            </IconButton>
         </Fragment>
      );
   }

   renderDebug() {
      if (this.props.isDebug) {
         return (
            <div className="debugBar">
               <ul>
                  <li>{this.props.shortcut}</li>
                  <li>
                     {this.state.formatPrefered} => {this.state.formatCurrent}
                  </li>
                  <li>
                     {this.state.bitratePrefered} => {this.state.bitrateCurrent}
                  </li>
                  <li>{this.state.playerShouldPlay ? "play" : "stop"}</li>
                  <li>{this.state.playerState}</li>
                  <li>{this.state.hasPlayError ? "error" : "ok"}</li>
                  <li>volume: {this.state.playerVolume}</li>
                  <li>pos: {this.state.playerStateLastPosition}</li>
               </ul>
               <ul>
                  <li>{this.state.streamUrl}</li>
               </ul>
            </div>
         );
      }

      return null;
   }
}

export default Player;

const styles = {
   card: {
      display: "flex",
      justifyContent: "space-between",
      height: 100,
      backgroundColor: "#555555",
      color: "#ffffff"
   },
   details: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1
   },
   onairBlock: {
      flex: "1 0 auto",
      padding: "0 10px",
      maxWidth: "280px"
   },
   onairSong: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      paddingTop: "8px"
   },
   onairArtist: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      paddingTop: "6px",
      fontSize: "0.8em"
   },
   onairImage: {
      width: 100,
      height: 100
   },
   controls: {
      display: "flex",
      alignItems: "center"
   },
   controlIcon: {
      height: 26,
      width: 26
   },
   toolbarIcon: {
      height: 18,
      width: 18,
      color: "#555555"
   },
   toolbarIconButton: {
      padding: "5px"
   },
   volumeSlider: {
      padding: "22px 0px"
   },

   radioTitle: {
      paddingLeft: "10px",
      paddingRight: "10px"
   },
   radioLogo: {
      marginRight: "10px",
      borderRadius: 0
   }
};
