import React, { Fragment, Component } from "react";
import HTTP from "../HTTP.js";
import Log from "../Log.js";

//import IsNull from "lodash/isNull";
import IsObject from "lodash/isObject";
import IsArray from "lodash/isArray";
import IsEmpty from "lodash/isEmpty";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import IconWeb from "@material-ui/icons/ExploreTwoTone";
import IconPhone from "@material-ui/icons/CallTwoTone";
import IconEmail from "@material-ui/icons/EmailTwoTone";
import IconAddress from "@material-ui/icons/MapTwoTone"; // Place

class RadioInfo extends Component {
   constructor(props) {
      Log.info("RadioInfo constructor", props);
      super(props);

      this.state = {
         title: "",
         description: "",
         profileLink: "",
         listeners: 0,
         logo: "",
         radioStyles: [],
         radioRegions: [],
         infoWeb1: "",
         infoWeb2: "",
         infoAddress: "",
         infoPhone1: "",
         infoPhone2: "",
         infoEmail1: "",
         infoEmail2: ""
      };
   }

   componentDidMount() {
      this.getRadioInfo();
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (prevProps.shortcut !== this.props.shortcut) {
         this.getRadioInfo();
      }
   };

   getRadioInfo() {
      let apiEndPoint = this.props.cfg.apiUrl + "getRadioInfo/" + this.props.shortcut;
      let quiteRequest = false; // optional

      HTTP.get(apiEndPoint, quiteRequest).then(
         function(json) {
            Log.info(" === RESPONSE for: " + apiEndPoint, json);
            // json.response

            if (!IsObject(json.data.basic)) {
               // handle error
               return false;
            }

            this.setState({
               title: !IsEmpty(json.data.basic.title) ? json.data.basic.title : "",
               description: !IsEmpty(json.data.basic.description) ? json.data.basic.description : "",
               profileLink: !IsEmpty(json.data.basic.url) ? json.data.basic.url : "",
               listeners: !IsEmpty(json.data.basic.listeners) ? json.data.basic.listeners : "",
               logo: !IsEmpty(json.data.basic.logoimg_m) ? json.data.basic.logoimg_m : "",
               radioStyles: !IsArray(json.data.basic.style_title) ? json.data.basic.style_title : [],
               radioRegions: !IsArray(json.data.basic.region_title) ? json.data.basic.region_title : [],
               infoWeb1: !IsEmpty(json.data.radio_info.web1) ? json.data.radio_info.web1 : "",
               infoWeb2: !IsEmpty(json.data.radio_info.web2) ? json.data.radio_info.web2 : "",
               infoAddress: !IsEmpty(json.data.radio_info.address) ? json.data.radio_info.address : "",
               infoPhone1: !IsEmpty(json.data.radio_info.phone1) ? json.data.radio_info.phone1 : "",
               infoPhone2: !IsEmpty(json.data.radio_info.phone2) ? json.data.radio_info.phone2 : "",
               infoEmail1: !IsEmpty(json.data.radio_info.email1) ? json.data.radio_info.email1 : "",
               infoEmail2: !IsEmpty(json.data.radio_info.email2) ? json.data.radio_info.email2 : ""
            });
         }.bind(this)
      );
   }

   render() {
      Log.info("render() RadioInfo");

      return (
         <div>
            <Card style={styles.card}>
               <CardContent style={styles.infoBlock}>
                  <List dense={true} style={styles.infoBlockList}>
                     {!IsEmpty(this.state.infoWeb1) ? (
                        <ListItem dense={true} divider={true}>
                           <div style={styles.twoButtonsBlock}>
                              <ListItemIcon>
                                 <IconWeb />
                              </ListItemIcon>
                              <div style={styles.twoButtonsItem}>
                                 <a
                                    href={this.state.infoWeb1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={styles.twoButtonsItemLink}
                                 >
                                    <span style={styles.twoButtonsItemText}>
                                       {this.state.infoWeb1.replace("http://", "").replace("https://", "")}
                                    </span>
                                 </a>
                                 {!IsEmpty(this.state.infoWeb2) ? (
                                    <Fragment>
                                       ,{" "}
                                       <a
                                          href={this.state.infoWeb2}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={styles.twoButtonsItemLink}
                                       >
                                          <span style={styles.twoButtonsItemText}>
                                             {this.state.infoWeb2
                                                .replace("http://", "")
                                                .replace("https://", "")}
                                          </span>
                                       </a>
                                    </Fragment>
                                 ) : null}
                              </div>
                           </div>
                        </ListItem>
                     ) : null}

                     {!IsEmpty(this.state.infoEmail1) ? (
                        <ListItem dense={true} divider={true}>
                           <div style={styles.twoButtonsBlock}>
                              <ListItemIcon>
                                 <IconEmail />
                              </ListItemIcon>
                              <div style={styles.twoButtonsItem}>
                                 <a
                                    href={"mailto:" + this.state.infoEmail1}
                                    style={styles.twoButtonsItemLink}
                                 >
                                    <span style={styles.twoButtonsItemText}>{this.state.infoEmail1}</span>
                                 </a>

                                 {!IsEmpty(this.state.infoEmail2) ? (
                                    <Fragment>
                                       ,{" "}
                                       <a
                                          href={"mailto:" + this.state.infoEmail2}
                                          style={styles.twoButtonsItemLink}
                                       >
                                          <span style={styles.twoButtonsItemText}>
                                             {this.state.infoEmail2}
                                          </span>
                                       </a>
                                    </Fragment>
                                 ) : null}
                              </div>
                           </div>
                        </ListItem>
                     ) : null}

                     {!IsEmpty(this.state.infoPhone1) ? (
                        <ListItem dense={true} divider={true}>
                           <ListItemIcon>
                              <IconPhone />
                           </ListItemIcon>
                           <ListItemText
                              primary={this.state.infoPhone1}
                              secondary={this.state.infoPhone2 ? this.state.infoPhone2 : null}
                           />
                        </ListItem>
                     ) : null}

                     {!IsEmpty(this.state.infoAddress) ? (
                        <ListItem dense={true}>
                           <ListItemIcon>
                              <IconAddress />
                           </ListItemIcon>
                           <ListItemText primary={this.state.infoAddress} />
                        </ListItem>
                     ) : null}
                  </List>
               </CardContent>
            </Card>
         </div>
      );
   }
}

export default RadioInfo;

const styles = {
   twoButtonsBlock: { display: "flex", justifyContent: "space-between" },
   twoButtonsItem: { paddingLeft: "16px", paddingRight: "16px", paddingTop: "4px" },
   twoButtonsItemLink: { marginRight: "2px" },
   twoButtonsItemText: {
      color: "#fff",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
   },

   card: {
      borderRadius: 0
   },
   infoBlock: {
      padding: "8px",
      fontSize: "0.8em"
   },
   infoBlockList: {}
};
