import React, { Component } from "react";
import HTTP from "../HTTP.js";
import Log from "../Log.js";

//import IsNull from "lodash/isNull";
//import IsObject from "lodash/isObject";
import IsArray from "lodash/isArray";
//import IsEmpty from "lodash/isEmpty";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import Avatar from "@material-ui/core/Avatar";

class OnairHistory extends Component {
   constructor(props) {
      Log.info("OnairHistory constructor", props);
      super(props);

      this.state = {
         songs: []
      };
   }

   componentDidMount() {
      this.getOnairHistory();
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (
         prevProps.shortcut !== this.props.shortcut ||
         prevProps.onairHistoryKey !== this.props.onairHistoryKey
      ) {
         this.getOnairHistory();
      }
   };

   getOnairHistory() {
      let apiEndPoint = this.props.cfg.onairUrl + this.props.shortcut + "-history.json";
      let quiteRequest = false; // optional

      HTTP.get(apiEndPoint, quiteRequest).then(
         function(json) {
            Log.info(" === RESPONSE for: " + apiEndPoint, json);
            // json.response

            if (!IsArray(json)) {
               // handle error
               return false;
            }

            this.setState({ songs: json });
         }.bind(this)
      );
   }

   render() {
      Log.info("render() OnairHistory");

      return (
         <div>
            <Card style={styles.card}>
               <CardContent style={styles.infoBlock}>
                  <List dense={true} style={styles.infoBlockList}>
                     {IsArray(this.state.songs) &&
                        this.state.songs.map((item, index) => {
                           return (
                              <ListItem dense={true} key={index}>
                                 <ListItemAvatar>
                                    <Avatar src={item.img} style={styles.onairCover} />
                                 </ListItemAvatar>
                                 <ListItemText primary={item.song} secondary={item.artist} />
                              </ListItem>
                           );
                        })}
                  </List>
               </CardContent>
            </Card>
         </div>
      );
   }
}

export default OnairHistory;

const styles = {
   card: {
      backgroundColor: "#555555",
      borderRadius: 0
   },
   infoBlock: {
      padding: "8px",
      fontSize: "0.8em"
   },
   infoBlockList: {},
   onairCover: {
      borderRadius: "3px"
   }
};
