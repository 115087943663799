//import Fetch from "whatwg-fetch";
import Log from "./Log.js";
var service = {
   handleErrorCode: function(statusCode, url, method, quiteRequest) {
      Log.rest("API (" + url + ":" + method + ") response: " + statusCode);
      switch (statusCode) {
         case 200:
            break;

         case 401:
            break;

         case 404:
            break;

         case 410:
            break;

         default:
            break;
      }
   },

   get: function(url, quiteRequest) {
      return fetch(url, {
         credentials: "same-origin",
         headers: {}
      }).then(
         function(response) {
            this.handleErrorCode(response.status, url, "get", quiteRequest);
            return response.json();
         }.bind(this),
         function(error) {
            this.handleErrorCode(410, url, "get", quiteRequest);
            return { error: 410 };
         }.bind(this)
      );
   },

   post: function(url, params, quiteRequest) {
      var reqData = new URLSearchParams();
      params.forEach(function(item) {
         reqData.append(item.key, item.val);
      });

      //console..info(reqData);

      return fetch(url, {
         credentials: "same-origin",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
         },
         method: "post",
         body: reqData
      }).then(
         function(response) {
            this.handleErrorCode(response.status, url, "post", quiteRequest);
            return response.json();
         }.bind(this),
         function(error) {
            this.handleErrorCode(410, url, "post", quiteRequest);
            return { error: 410 };
         }.bind(this)
      );
   },

   postJSON: function(url, jsonbody, quiteRequest) {
      return fetch(url, {
         credentials: "same-origin",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
         },
         method: "post",
         body: JSON.stringify(jsonbody)
      }).then(
         function(response) {
            this.handleErrorCode(response.status, url, "postJSON", quiteRequest);
            return response.json();
         }.bind(this),
         function(error) {
            this.handleErrorCode(410, url, "postJSON", quiteRequest);
            return { error: 410 };
         }.bind(this)
      );
   },

   delete: function(url, jsonbody, quiteRequest) {
      return fetch(url, {
         credentials: "same-origin",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
         },
         method: "delete",
         body: JSON.stringify(jsonbody)
      }).then(
         function(response) {
            this.handleErrorCode(response.status, url, "delete", quiteRequest);
            return response.json();
         }.bind(this),
         function(error) {
            this.handleErrorCode(410, url, "delete", quiteRequest);
            return { error: 410 };
         }.bind(this)
      );
   }
};

// module.exports = service;
export default service;
