import React, { Fragment, Component } from "react";
import { Helmet } from "react-helmet";

import "./App.css";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";

import Log from "./Log.js";
import Konami from "react-konami-code";

import IsEmpty from "lodash/isEmpty";
import queryString from "query-string";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";

import { DFPSlotsProvider, AdSlot } from "react-dfp";

import Player from "./components/Player.jsx";
import RssFeed from "./components/RssFeed.jsx";
import RadioInfo from "./components/RadioInfo.jsx";
import OnairHistory from "./components/OnairHistory.jsx";

/* https://bitbucket.org/playcz/api.play.cz/wiki/Home */
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shortcut: null,
      formatPrefered: null,
      bitratePrefered: null,

      activeTab: 0,

      onairHistoryKey: 0,

      isDebug: false,
    };

    this.cfg = {
      apiUrl: "https://api.play.cz/json/",
      onairUrl: "https://onair.play.cz/json/",
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    Log.info("Query string", window.location.search);

    const query = queryString.parse(window.location.search);
    Log.info("Query string", { query });

    // try to load shortcut from URL / or localStore
    var shortcut = query && query.shortcut;
    if (IsEmpty(shortcut)) {
      shortcut = localStorage.getItem("playerLastShortcut");
      Log.info("Query string empty, using localStorage", shortcut);
      if (IsEmpty(shortcut)) {
        shortcut = "nostream";
      }
    }

    localStorage.setItem("playerLastShortcut", shortcut); //hotfix

    var formatPrefered = query && query.format;
    if (IsEmpty(formatPrefered)) {
      formatPrefered = localStorage.getItem("playerPreferedFormat");
    }

    var bitratePrefered = query && query.bitrate;
    if (IsEmpty(bitratePrefered)) {
      bitratePrefered = localStorage.getItem("playerPreferedBitrate");
    }

    Log.info("query - shortcut", shortcut);
    Log.info("query - format", formatPrefered);
    Log.info("query - bitrate", bitratePrefered);

    this.setState({ shortcut, formatPrefered, bitratePrefered });
  }

  handleTabChange(event, value) {
    Log.info("handleTabChange", { value, event });
    this.setState({ activeTab: value });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>Player : PLAY.CZ</title>
        </Helmet>
        <DFPSlotsProvider dfpNetworkId={"17638894"}>
          <div className="App">
            {this.renderDebugRadioSwitch()}

            <Fragment>{this.renderMainPlayer()}</Fragment>

            {/* IDDQD */}
            <Konami
              code={[73, 68, 68, 81, 68]}
              action={() => {
                this.setState({ isDebug: true });
              }}
            />
          </div>
        </DFPSlotsProvider>
      </MuiThemeProvider>
    );
  }

  renderMainPlayer() {
    if (IsEmpty(this.state.shortcut)) {
      return null;
    }

    return (
      <Fragment>
        <div style={{ textAlign: "center" }}>
          <AdSlot sizes={[[400, 50]]} adUnit="PLAY.CZ_PLAYER_400x50" />
        </div>

        <Player
          shortcut={this.state.shortcut}
          format={this.state.formatPrefered}
          bitrate={this.state.bitratePrefered}
          enableFavorites={false}
          cfg={this.cfg}
          isDebug={this.state.isDebug}
          onSongChange={(song, artist) => {
            Log.info("OnSongChange callback", artist + " - " + song);
            this.setState((state) => {
              var onairHistoryKey = state.onairHistoryKey;
              onairHistoryKey++;
              if (onairHistoryKey > 9) onairHistoryKey = 0;
              return { onairHistoryKey };
            });
          }}
        />

        <Tabs
          value={this.state.activeTab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
        >
          <Tab label="PLAY.CZ" />
          <Tab label="Rádio" />
          <Tab label="Hrálo" />
        </Tabs>

        <div style={{ maxHeight: "200px", overflow: "auto" }}>
          {this.state.activeTab === 0 && (
            <RssFeed
              feed="https://www.play.cz/feed/"
              cfg={this.cfg}
              isDebug={this.state.isDebug}
            />
          )}
          {this.state.activeTab === 1 && (
            <RadioInfo
              shortcut={this.state.shortcut}
              cfg={this.cfg}
              isDebug={this.state.isDebug}
            />
          )}
          {this.state.activeTab === 2 && (
            <OnairHistory
              shortcut={this.state.shortcut}
              onairHistoryKey={this.state.onairHistoryKey}
              cfg={this.cfg}
              isDebug={this.state.isDebug}
            />
          )}
        </div>

        <Divider variant="fullWidth" />

        <div style={{ textAlign: "center", margin: "6px auto" }}>
          <AdSlot
            sizes={[
              [300, 250],
              [336, 280],
            ]}
            adUnit="PLAY.CZ_PLAYER_300x250"
          />
        </div>

        <Divider variant="fullWidth" />

        <div
          style={{
            fontSize: "0.7em",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            padding: "2px 6px 0 6px",
            backgroundColor: "rgba(90,90,90,0.95)",
          }}
        >
          <span>&copy; PLAY.cz | v{process.env.REACT_APP_VERSION}</span>
          <span>{" | "}</span>
          <span>
            <a
              href="https://www.play.cz/zasady-ochrany-osobnich-udaju/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zásady ochrany osobních údajů
            </a>
          </span>
          <span>{" | "}</span>
          <span>
            <a
              href="https://www.play.cz/zasady-ochrany-osobnich-udaju/#cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookies
            </a>
          </span>
        </div>
      </Fragment>
    );
  }

  renderDebugRadioSwitch() {
    if (this.state.isDebug) {
      const query = queryString.parse(window.location.search);
      return (
        <div className="debugBar">
          <ul>
            <li>q: {query && query.shortcut}</li>
            <li>ls: {localStorage.getItem("playerLastShortcut")}</li>
            <li>sh: {this.state.shortcut}</li>
            <li>f: {this.state.formatPrefered}</li>
            <li>b: {this.state.bitratePrefered}</li>
            <li>
              <button
                onClick={() => {
                  this.setState({ shortcut: "cro1" });
                }}
              >
                cro1
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  this.setState({ shortcut: "kiss" });
                }}
              >
                kiss
              </button>
            </li>
          </ul>
        </div>
      );
    }

    return null;
  }
}

export default App;

const theme = createTheme({
  palette: {
    type: "dark",
    primary: orange,
    secondary: {
      main: "#9e9e9e",
    },
  },
  typography: {
    useNextVariants: true,
  },
});
