import React, { Component } from "react";
import HTTP from "../HTTP.js";
import Log from "../Log.js";

//import IsNull from "lodash/isNull";
import IsObject from "lodash/isObject";
import IsArray from "lodash/isArray";
//import IsEmpty from "lodash/isEmpty";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import Dotdotdot from "react-dotdotdot";

class RssFeed extends Component {
   constructor(props) {
      Log.info("RssFeed constructor", props);
      super(props);

      this.state = {
         feedTitle: "",
         feedItems: []
      };

      this.loadFeedData = this.loadFeedData.bind(this);
   }

   componentDidMount() {
      this.loadFeedData();
   }

   loadFeedData() {
      let apiEndPoint = this.props.cfg.apiUrl + "getFeed/?feedid=playcz";
      let quiteRequest = false; // optional

      HTTP.get(apiEndPoint, quiteRequest).then(
         function(json) {
            Log.info(" === RESPONSE for: " + apiEndPoint, json);
            // json.response

            if (!IsObject(json.data.meta) || !IsArray(json.data.item)) {
               // handle error
               return false;
            }

            this.setState({ feedTitle: json.data.meta.title, feedItems: json.data.item });
         }.bind(this)
      );
   }

   render() {
      if (!IsArray(this.state.feedItems)) return <div />;

      Log.info("render() RssFeed", this.state.feedItems);

      return (
         <div>
            {IsObject(this.state.feedItems[0]) ? (
               <Link href={this.state.feedItems[0].link} target="_blank" color="inherit" underline="none">
                  <Card style={styles.cardArticle}>
                     <div style={styles.article}>
                        <CardContent style={styles.articleBlock}>
                           <div style={styles.articleBlockTitle}>{this.state.feedItems[0].title}</div>
                           <Dotdotdot clamp={3}>
                              {this.state.feedItems[0].description.replace("Pokračovat &#187;", "")}
                           </Dotdotdot>
                        </CardContent>
                     </div>
                     <CardMedia
                        style={styles.articleImage}
                        image={this.state.feedItems[0].image}
                        title="lorem ipsum"
                     />
                  </Card>
               </Link>
            ) : null}

            <Card style={styles.cardFeed}>
               <CardContent style={styles.feedBlock}>
                  <div>
                     <ul style={styles.feedList}>
                        {this.state.feedItems.map((item, index) => {
                           if (index === 0) return null;
                           return (
                              <li key={index} style={styles.feedListItem}>
                                 <Link href={item.link} target="_blank" color="inherit">
                                    • {item.title}
                                 </Link>
                              </li>
                           );
                        })}
                     </ul>
                  </div>
               </CardContent>
            </Card>
         </div>
      );
   }
}

export default RssFeed;

const styles = {
   cardArticle: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "0.9em",
      borderRadius: 0
   },

   article: {
      display: "flex",
      flexDirection: "column",
      width: "220px",
      flexGrow: 1
   },
   articleBlock: {
      fontSize: "0.9em",
      padding: "10px 8px 0 8px"
   },
   articleBlockTitle: {
      fontWeight: 700,
      marginBottom: "6px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
   },
   articleImage: {
      width: 85,
      height: 85
   },

   cardFeed: {
      borderRadius: 0
   },
   feedBlock: {
      padding: "8px",
      fontSize: "0.8em"
   },
   feedList: {
      margin: 0,
      paddingLeft: "5px"
   },
   feedListItem: {
      display: "list-item",
      listStyleType: "none",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
   }
};
